<template>
  <div>
    <CCard class="col-md-12">
      <CRow class="p-3">
       <h4 class="color-light ml-2 page_title"><span class="ul_text">PO</span>STBACK SECURITY</h4>
       </CRow>
      <CCardBody>
        <CRow>
          <CCol class="col-md-9">
              <CInput
              label="Postback IP Whitelist"
                type="text"
                size="sm"
                v-model="mainObject.ip_whitelist"
                required
                placeholder=""
                :state="validation"
                append="X"
                maxlength="2000"
              ></CInput>
            <p style="font-size: 12px;">
              Comma separated list of IPs in <a href=""> CIDR notation </a>. The
              Postback IP Whitelist can help avoid postback fraud when the
              source IP addresses of the devices sending us postbacks are known.
              By setting this option, we will only accept postbacks from IP
              addresses listed above.
            </p>
          </CCol>
          <!-- <b-form-invalid-feedback
            style="margin-left:160px;"
            :state="validation"
            >Please add a name for the traffic source which will help you
            identify it later</b-form-invalid-feedback
          > -->
        </CRow>
        <CRow class="">
          <CCol class="col-md-9">
              <CInput
              label="Postback Password"
                type="text"
                size="sm"
                v-model="mainObject.postback_password"
                required
                placeholder=""
                :state="validation"
                append="X"
                maxlength="100"
              ></CInput>
              
            <p style="font-size: 12px;">
              The Postback Password can help avoid postback fraud. By setting
              this option, we will only accept postbacks which have the password
              in the <b style="color:#ba0e0e;"> key </b> parameter. Use this
              feature when the source IP addresses of the devices sending us
              postbacks are unknown.
            </p>
            <CInputCheckbox
            label="I recognise that any conversion postbacks which do not pass the
                security settings I have just configured will be rejected.
                Server IPs which send postbacks must be within the ranges I have
                defined, and/or the postback parameter must contain my
                postback password."
              value="true"
              unchecked-value="false"
              :checked.sync="mainObject.postipcheck"
            >
            </CInputCheckbox>
          </CCol>
          <!-- <b-form-invalid-feedback
            style="margin-left:160px;"
            :state="validation"
            >Please add a name for the traffic source which will help you
            identify it later</b-form-invalid-feedback
          > -->
        </CRow>
        <CRow class="mt-3">
          <CButton type="submit" v-on:click="onSubmit" color="success"
            >Save</CButton
          >
        </CRow>
      </CCardBody></CCard
    >
  </div>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import SettingService from "./../../services/settingControlService";
import HelperFunction from "./../../helper/helperFunction";
export default {
  data() {
    return {
      mainObject: {
        ip_whitelist: "",
        postback_password: "",
        postipcheck: false,
      }
    };
  },
  async mounted() {
    if(window.localStorage.getItem('user') && window.localStorage.getItem('status') != 'true'){
          this.$router.push('/')
        }
    else{
    try {
      let response = await SettingService.postipget();

      if (response.message) {
        this.mainObject.ip_whitelist = response.message.postipwhlist.join(",");
        this.mainObject.postback_password = response.message.postippwd;
        this.mainObject.postipcheck = response.message.postipcheck;
      }
    } catch (e) {
      console.log('connection error')
      // this.errorNotify("Error", "connection error", "danger");
    }
    }
  },
  computed: {
    validation() {
      //   if (this.mainObject.offername == "") {
      //     return false;
      //   }
    },
  },
  methods: {
    async onSubmit() {
      if(this.validate()){
      let postback_data = {
          postipwhlist:
            this.mainObject.ip_whitelist.length > 0
              ? this.mainObject.ip_whitelist.split(",")
              : [],
          postippwd: this.mainObject.postback_password,
          postipcheck: this.mainObject.postipcheck
        };
        let response = await SettingService.postip(postback_data);
        if(response.result){
          Swal.fire({title: 'Success!',text: response.message,icon: 'success',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
        }
        else{
          Swal.fire({title: 'Error!',text: response.message,icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
        }
      }
    },
    validate(){
      if((this.mainObject.ip_whitelist.length >0 || this.mainObject.postback_password != '') &&  this.mainObject.postipcheck == false ){
          Swal.fire({title: 'Error!',text: "Please read the condition and click on the checkbox",icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
          return false;
      }
      else if(HelperFunction.validateCIDRIPs(this.mainObject.ip_whitelist) || HelperFunction.validateIPs(this.mainObject.ip_whitelist)){
          return true;
      }
      else{
          Swal.fire({title: 'Error!',text: "Invalid IP",icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
          return false
      }
    }
  },
};
</script>

<style>
label {
  text-align: left;
  font-weight: 400;
  color: black;
  font-size: 12px;
}
</style>
